<template>
  <b-card-code>
    <b-row>
      <b-col sm="6">
        <b-button-group
          v-if="!loading && filename"
          class="mr-4"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            :href="`${uri}/profits/download/file/${filename}`"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            />
            Descargar Excel
          </b-button>
        </b-button-group>
        <b-button-group>
          <b-form-group class="mr-1 mt-1">
            <v-select
              v-model="period"
              :options="periods"
              label="label"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              :reduce="option => option.periodo"
              style="border: 1px #c4c4c4 solid; border-radius: 8px; height: 40px; width: 120px"
              @input="load(period)"
            />
          </b-form-group>
        </b-button-group>
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            type="button"
            variant="primary"
            :disabled="loading"
            @click="updateDistribution(period)"
          >
            Calcular Distribución
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <b-row
      v-if="errorMsg"
      class="mt-3 mb-3"
    >
      <b-col>
        <b-alert
          variant="primary"
          show
        >
          <div class="alert-body">
            <span>{{ errorMsg }}</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </b-card-code>
</template>

<script>
import {
  BAlert, BFormGroup, BRow, BCol, BButtonGroup,
  BButton,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { downloadExcel } from '@core/utils/utils'
import environment from '@/environment'
import store from '@/store/index'

export default {
  name: 'CentersTypesVue',
  components: {

    BAlert,
    BFormGroup,
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BCardCode,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      uri: environment.uri,
      filename: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      errorMsg: '',
      loading: true,
      period: '',
      pageLength: 100,
      dir: false,
      option: [{ title: '2021' }, { title: '2020' }],
      periods: [],
      columns: [
        { globalSearchDisabled: true, label: 'ID', field: 'id' },
        { globalSearchDisabled: true, label: 'Periodo', field: 'periodo' },
        { globalSearchDisabled: true, label: 'Nivel', field: 'nivel' },
        { globalSearchDisabled: true, label: 'ID Gerencia \n Origen', field: 'idGerenciaOrigen' },
        { globalSearchDisabled: true, label: 'Gerencia \n Origen', field: 'gerenciaOrigen' },
        { globalSearchDisabled: true, label: 'ID Centro \n Origen', field: 'idCentroOrigen' },
        { globalSearchDisabled: true, label: 'Centro \n Origen', field: 'centroOrigen' },
        {
          label: 'Cuenta \n Origen',
          field: 'cuentaOrigen',
          filterOptions: {
            styleClass: 'class1',
            enabled: true,
            placeholder: 'Buscar',
            filterValue: '',
            filterFn: (data, filterString) => (String(data).includes(String(filterString))),
          },
        },
        { globalSearchDisabled: true, label: 'Tipo \n Cuenta', field: 'tipoCuenta' },
        { globalSearchDisabled: true, label: 'Categoría \n Cuenta', field: 'categoriaCuenta' },
        { globalSearchDisabled: true, label: 'ID Centro \n Destino', field: 'idCentroDestino' },
        { globalSearchDisabled: true, label: 'Monto', field: 'monto' },
        { globalSearchDisabled: true, label: 'Porcentaje', field: 'porcentaje' },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Preactivo: 'light-preactivoCda',
        Inactivo: 'light-secondary',
        Activo: 'light-primary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.$http.get(`${environment.uri}/workflows/periods`).then(periodsResp => {
      if (periodsResp && periodsResp.data) {
        this.periods = periodsResp.data.filter(p => p.status !== 'P')
        this.period = this.periods[0].periodo
      }
      this.load(this.period)
    })
  },
  methods: {
    download() {
      downloadExcel(this.rows, [
        'id',
        'periodo',
        'nivel',
        'idGerenciaOrigen',
        'gerenciaOrigen',
        'idCentroOrigen',
        'centroOrigen',
        'cuentaOrigen',
        'tipoCuenta',
        'categoriaCuenta',
        'idCentroDestino',
        'monto',
        'porcentaje',
      ], 'movimientos-distribucion.csv')
    },
    async load(period) {
      this.errorMsg = 'Un Momento...'
      this.loading = true
      try {
        const resp = await this.$http.post(`${environment.uri}/profits/queue/id/${period}`, {
          username: this.userData.username,
          sessionId: this.userData.token,
        }, { timeout: 180000 })

        if (resp && resp.data && !resp.data.error) {
          this.filename = resp.data.filename
          this.errorMsg = ''
        } else {
          this.filename = ''
          this.errorMsg = 'No existen una distribución de movimientos asociado a las cuentas contables del periodo VARPERIODO, Para efectuar el cálculo debe presionar el botón "Actualizar Distribución".'
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },
    async updateDistribution(period) {
      this.errorMsg = 'Un Momento...'
      this.loading = true
      try {
        await this.$http.post(`${environment.uri}/profits/accountDistribution`, {
          id: period,
        }, { timeout: 180000 })
        await this.load(this.period)

        this.errorMsg = 'Actualizado'
      } catch (err) {
        console.error(err)
        this.errorMsg = 'Algo salio mal'
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
